<template>
	<div class="tool-page">
		<Introduce
			:title="$t('tools.alpha-generator.title')"
			:titleBefore="$t('tools.alpha-generator.title-sm1')"
			:titleSm="$t('tools.alpha-generator.title-sm2')"
			:content="$t('tools.alpha-generator.content')"
			:listFeature="listFeature"
		>
			<template #button>
				<b-button variant="primary" class="custom-btn" @click="scrollTo()">
          <div class="text-white-button button-large text-nowrap d-flex justify-content-center">
            {{ $t("tools.alpha-generator.download") }}
          </div>
				</b-button>
			</template>
			<template #itemRight>
				<div class="layout-right">
					<img src="@/assets/toolImg/alpha1.png" alt="" class="img1">
				</div>
			</template>
		</Introduce>
		<div class="title title-tool-page">
			{{ $t("tools.alpha-generator.begin1") }}
			<br>
			<span class="text-primary">
				{{ $t("tools.alpha-generator.begin2") }}
			</span>
		</div>
		<!-- row 1 -->
		<div class="content-item">
			<div class="content-item__info">
				 <div class="content-item__info__title">
					{{ $t("tools.alpha-generator.content-item-title1") }}
				 </div>
				 <div class="content-item__info__note">
					{{ $t("tools.alpha-generator.content-item-note1") }}
				 </div>
				 <div class="content-item__info__text">
					{{ $t("tools.alpha-generator.content-item-text1") }}
					<div class="hinde-sm enter-paragraphs"></div>
					{{ $t("tools.alpha-generator.content-item-text2") }}
				 </div>
			</div>
			<img src="@/assets/toolImg/alpha3.png" alt="">
		</div>
		<!-- row 2 -->
		<div class="content-item-full">
			<Dot3 class="dot-1" />
			<Dot4 class="dot-2" />
			<div class="content-item-full__title">
				{{ $t("tools.alpha-generator.content-item-title2") }}
			</div>
			<div class="content-item-full__info">
				<div>
					<img src="@/assets/toolImg/alpha4.png" alt="" class="hinde-md">
					<div class="content-item-full__info__note">
						{{ $t("tools.alpha-generator.content-item-note2") }}
					</div>
					<div class="content-item-full__info__text">
						{{ $t("tools.alpha-generator.content-item-text3") }}
					</div>
					<img src="@/assets/toolImg/alpha4.png" alt="" class="hinde-sm">
				</div>
				<div>
					<img src="@/assets/toolImg/alpha5.png" alt="" class="hinde-md">
					<div class="content-item-full__info__note">
						{{ $t("tools.alpha-generator.content-item-note3") }}
					</div>
					<div class="content-item-full__info__text">
						{{ $t("tools.alpha-generator.content-item-text6") }}
					</div>
					<img src="@/assets/toolImg/alpha5.png" alt="" class="hinde-sm">
				</div>
			</div>
		</div>
		<!-- row 3 -->
		<div class="content-item-full">
			<div class="content-item-full__title">
				{{ $t("tools.alpha-generator.content-item-title3") }}
				<div class="content-item-full__title__note">
					{{ $t("tools.alpha-generator.content-item-text7") }}
					<span class="bold">
						{{ $t("tools.alpha-generator.content-item-text8") }}
					</span>
					{{ $t("tools.alpha-generator.content-item-text9") }}
				</div>
			</div>
			<div class="content-item-full__info">
				<div>
					<img src="@/assets/toolImg/alpha6.png" alt="">
					<div class="content-item-full__info__note">
						{{ $t("tools.alpha-generator.content-item-note4") }}
					</div>
					<div class="content-item-full__info__text">
						{{ $t("tools.alpha-generator.content-item-text10") }}
						<span class="bold">
							{{ $t("tools.alpha-generator.content-item-text11") }}
						</span>
						{{ $t("tools.alpha-generator.content-item-text12") }}
						<div class="hinde-sm enter-paragraphs"></div>
						{{ $t("tools.alpha-generator.content-item-text13") }}
						<span class="bold">
							{{ $t("tools.alpha-generator.content-item-text14") }}
						</span>
					</div>
				</div>
				<div>
					<img src="@/assets/toolImg/alpha7.png" alt="">
					<div class="content-item-full__info__note">
						{{ $t("tools.alpha-generator.content-item-note5") }}
					</div>
					<div class="content-item-full__info__text">
						{{ $t("tools.alpha-generator.content-item-text15") }}
						<div class="hinde-sm enter-paragraphs"></div>
						{{ $t("tools.alpha-generator.content-item-text16") }}
						<span class="bold">
							{{ $t("tools.alpha-generator.content-item-text17") }}
						</span>
						{{ $t("tools.alpha-generator.content-item-text18") }}
					</div>
				</div>
			</div>
			<div class="content-item-full__info">
				<div>
					<img src="@/assets/toolImg/alpha8.png" alt="">
					<div class="content-item-full__info__note">
						{{ $t("tools.alpha-generator.content-item-note6") }}
					</div>
					<div class="content-item-full__info__text">
						{{ $t("tools.alpha-generator.content-item-text19") }}
						<span class="bold">
							{{ $t("tools.alpha-generator.content-item-text20") }}
						</span>
						{{ $t("tools.alpha-generator.content-item-text21") }}
						<div class="hinde-sm enter-paragraphs"></div>
						{{ $t("tools.alpha-generator.content-item-text22") }}
					</div>
				</div>
				<div>
					<img src="@/assets/toolImg/alpha9.png" alt="">
					<div class="content-item-full__info__note">
						{{ $t("tools.alpha-generator.content-item-note7") }}
					</div>
					<div class="content-item-full__info__text">
						{{ $t("tools.alpha-generator.content-item-text23") }}
						<span class="bold">
							{{ $t("tools.alpha-generator.content-item-text24") }}
						</span>
						{{ $t("tools.alpha-generator.content-item-text25") }}
						<div class="hinde-sm enter-paragraphs"></div>
						<span class="bold">
							{{ $t("tools.alpha-generator.content-item-text26") }}
						</span> 
						{{ $t("tools.alpha-generator.content-item-text27") }}
					</div>
				</div>
			</div>
		</div>
		<!-- row 4 -->
		<div class="content-item">
			<div class="content-item__info">
				<div class="content-item__info__title-lg">
					{{ $t("tools.alpha-generator.content-item-title4") }}
				</div>
				<div class="content-item__info__text">
					<span ref="download" class="underline" @click="download()"> {{ $t("tools.alpha-generator.content-item-text28") }}</span>
					{{ $t("tools.alpha-generator.content-item-text29") }}
					<div class="hinde-sm enter-paragraphs"></div>
					{{ $t("tools.alpha-generator.content-item-text30") }}
					<span class="underline" @click="contactUs()"> {{ $t("tools.alpha-generator.content-item-text31") }}</span>
					{{ $t("tools.alpha-generator.content-item-text32") }}
				</div> 
				<img src="@/assets/toolImg/alpha10.png" alt="" class="img-sm">
				<div class="content-item__info__note">
					{{ $t("tools.alpha-generator.content-item-note8") }}
				</div>
				<div class="content-item__info__text">
					<span class="underline bold-sm" @click="download()"> {{ $t("tools.alpha-generator.content-item-text33") }}</span>
					{{ $t("tools.alpha-generator.content-item-text34") }}
				</div>
				<div class="content-item__info__note">
					{{ $t("tools.alpha-generator.content-item-note9") }}
				</div>
				<div class="content-item__info__text">
					{{ $t("tools.alpha-generator.content-item-text35") }}
					<span class="underline bold-sm" @click="contactUs()">{{ $t("tools.alpha-generator.content-item-text36") }}</span>
					{{ $t("tools.alpha-generator.content-item-text37") }}
				</div>
				<!-- <div class="content-item__info__text">
					{{ $t("tools.alpha-generator.content-item-text38") }}
					<span class="underline bold-all">{{ $t("tools.alpha-generator.content-item-text39") }}</span>
				</div> -->
			</div>
			<div class="img-group">
				<Dot1 class="dot-1" />
				<Dot2 class="dot-2" />
				<Dot3 class="dot-3" />
				<Dot4 class="dot-4" />
				<Vector class="vector" />
				<img src="@/assets/toolImg/alpha10.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
import Introduce from "@/components/tool/Introduce.vue";
import Vector from "@/svg/tool/Vector.vue";
import Dot1 from "@/svg/tool/Dot1.vue";
import Dot2 from "@/svg/tool/Dot2.vue";
import Dot3 from "@/svg/tool/Dot3.vue";
import Dot4 from "@/svg/tool/Dot4.vue";
export default {
	components: {
		Introduce,
		Vector,
		Dot1,
		Dot2,
		Dot3,
		Dot4,
	},
	computed: {
		listFeature() {
			return [
				this.$t("tools.alpha-generator.feature1"),
				this.$t("tools.alpha-generator.feature2"),
				this.$t("tools.alpha-generator.feature3"),
			]
		}
  },
	data() {
		return {
			
		}
	},
	mounted() {
		
	},
	watch: {

	},
	methods: {
		scrollTo() {
			this.$refs.download.scrollIntoView({ behavior: 'smooth',  block: "center" });
		},
		contactUs() {
			window.open("https://www.tradingcentral.com/contact-page", '_blank');
		},
		download() {
			window.open("https://global.tradingcentral.com/mt/TRADING_CENTRAL_MetaTrader_Analytics_v_4.0.2404.43.exe", "_parent");
			
		}
	},
	metaInfo: {
		title: "NOZAX: Alpha Generator",
		meta: [
			{
				name: "description",
				content: "" ,
			},
		],
	},
};
</script>
<style lang="scss" scoped>
.title {
	@media (max-width: 880px) {
		margin-top: 64px;
  }
}
.content-item {
	margin-top: 96px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:last-child {
		margin-bottom: 110px;
	}
	&__info {
		width: 529px;
		&__title {
			font-size:  40px;
			font-weight: 500;
			font-family: Roboto;
			letter-spacing: -0.28px;
			width: 366px;
			color: #484C57;
		}
		&__title-lg {
			font-size:  40px;
			font-weight: 500;
			font-family: Roboto;
			letter-spacing: -0.28px;
			width: 366px;
			color: #484C57;
		}
		&__note {
			font-size: 24px;
			font-weight: 400;
			font-family: Roboto;
			color: var(--primary);
			margin-top: 16px;
		}
		&__text {
			font-size: 18px;
			font-weight: 400; 
			margin-top: 16px;
			color: #616A70;
			margin-bottom: 32px;
		}
		.img-sm {
			display: none;
		}
	}
	.img-group {
		position: relative;
		.dot-1 {
			position: absolute;
			top: -16px;
			left: 280px;
		}
		.dot-2 {
			position: absolute;
			top: 345px;
			left: -30px;
		}
		.dot-3 {
			position: absolute;
			top: 88px;
			left: 564px;
		}
		.dot-4 {
			position: absolute;
			top: 266px;
			left: 578px;
		}
		.vector {
			position: absolute;
			top: 50px;
			left: -67px;
		}
	}
	@media (max-width: 1160px) {
    &__info {
			width: 333px;
			&__text {
				font-size: 16px;
			}
		}
		img {
			width: 420px;
		}
  }
	@media (max-width: 880px) {
		display: block;
		margin-top: 56px;
    &__info {
			width: 100%;
			&__title {
				font-size: 20px;
				letter-spacing: 0;
				width: 70%;
				min-width: 260px;
			}
			&__title-lg {
				font-size:  28px;
			}
			.img-sm {
				display: block;
				width: 100%;
				margin-bottom: 16px;
			}
		}
		.img-group {
			display: none;
		}
		img {
			width: 100%;
		}
  }
}
.content-item-full {
	margin-top: 120px;
	text-align: center;
	position: relative;
	.dot-1 {
		position: absolute;
		top: 480px;
    right: 0;
	}
	.dot-2 {
		position: absolute;
		top: 50px;
    left: 100px;
	}
	&__title {
		font-size: 40px;
		font-weight: 500;
		font-family: Roboto;
		letter-spacing: -0.4px;
		color: #484C57;
		&__note {
			font-size: 18px;
			font-weight: 400;
			margin-top: 32px;
			max-width: 777px;
			margin-left: auto;
			margin-right: auto;
			color: #616A70;
		}
	}
	&__info {
		display: flex;
		text-align: left;
		justify-content: space-between;
		margin-top: 70px;
		&__note {
			color: var(--primary);
			font-family: Roboto;
			font-size: 24px;
			font-weight: 400;
			margin-top: 48px;
		}
		&__text {
			margin-top: 24px;
			font-size: 18px;
			font-weight: 400;
			max-width: 548px;
			color: #616A70;
		}
	}
	@media (max-width: 1160px) {
		&__info {
			&__text {
				width: 410px;
			}
			img {
				width: 410px;
			}
		}
  }
	@media (max-width: 880px) {
		margin-top: 56px;
		margin-bottom: 24px;
		.dot-1 {
			display: none;
		}
		.dot-2 {
			display: none;
		}
		&__title {
			font-size: 20px;
			text-align: left;
		}
		&__info {
			display: block;
			margin-top: 0;
			&__note {
				font-size: 20px;
				margin-top: 0;
			}
			&__text {
				font-size: 18px;
				width: 100%;
			}
		}
		img {
			width: 100%;
			margin-top: 32px;
			margin-bottom: 32px;
		}
  }
}
.layout-right {
	margin-top: 75px;
	width: 550px;
	height: auto;
	img {
		width: 100%;
	}
	@media (max-width: 1160px) {
		width: 392px;
    position: absolute;
		right: 0;
		margin-top: 160px;
  }
	@media (max-width: 880px) {
    position: relative;
		margin-top: 80px;
		width: 100%;
		img {
			width: 100%;
		}
  }
}
</style>