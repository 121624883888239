var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "7",
      "height": "7",
      "viewBox": "0 0 7 7",
      "fill": "none"
    }
  }, [_c('ellipse', {
    attrs: {
      "cx": "3.5",
      "cy": "3.5",
      "rx": "3.5",
      "ry": "3.5",
      "transform": "matrix(-4.37114e-08 -1 -1 4.37114e-08 7 7)",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }