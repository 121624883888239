var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "687",
      "height": "162",
      "viewBox": "0 0 687 162",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "d": "M63 39C18 56 1 72.4547 1 96.5035C1 197.625 211.852 82.4732 310.497 119.469C409.141 156.465 575.119 184.769 661.918 131.801C698.293 109.604 698.765 33.6796 618 1",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-dasharray": "4.66 4.66"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }