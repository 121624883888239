var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "6",
      "height": "6",
      "viewBox": "0 0 6 6",
      "fill": "none"
    }
  }, [_c('mask', {
    attrs: {
      "id": "path-1-inside-1_5927_20570",
      "fill": "white"
    }
  }, [_c('ellipse', {
    attrs: {
      "cx": "3",
      "cy": "3",
      "rx": "3",
      "ry": "3",
      "transform": "matrix(-4.37114e-08 -1 -1 4.37114e-08 6 6)"
    }
  })]), _c('path', {
    attrs: {
      "d": "M3 4C3.55228 4 4 3.55229 4 3L-4 3C-4 -0.865994 -0.865994 -4 3 -4L3 4ZM4 3C4 2.44771 3.55228 2 3 2L3 10C-0.865993 10 -4 6.86599 -4 3L4 3ZM3 2C2.44772 2 2 2.44771 2 3L10 3C10 6.86599 6.86599 10 3 10L3 2ZM2 3C2 3.55229 2.44772 4 3 4L3 -4C6.86599 -4 10 -0.865994 10 3L2 3Z",
      "fill": "#07B53B",
      "mask": "url(#path-1-inside-1_5927_20570)"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }