var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tool-page"
  }, [_c('Introduce', {
    attrs: {
      "title": _vm.$t('tools.alpha-generator.title'),
      "titleBefore": _vm.$t('tools.alpha-generator.title-sm1'),
      "titleSm": _vm.$t('tools.alpha-generator.title-sm2'),
      "content": _vm.$t('tools.alpha-generator.content'),
      "listFeature": _vm.listFeature
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function () {
        return [_c('b-button', {
          staticClass: "custom-btn",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.scrollTo();
            }
          }
        }, [_c('div', {
          staticClass: "text-white-button button-large text-nowrap d-flex justify-content-center"
        }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.download")) + " ")])])];
      },
      proxy: true
    }, {
      key: "itemRight",
      fn: function () {
        return [_c('div', {
          staticClass: "layout-right"
        }, [_c('img', {
          staticClass: "img1",
          attrs: {
            "src": require("@/assets/toolImg/alpha1.png"),
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "title title-tool-page"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.begin1")) + " "), _c('br'), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.begin2")) + " ")])]), _c('div', {
    staticClass: "content-item"
  }, [_c('div', {
    staticClass: "content-item__info"
  }, [_c('div', {
    staticClass: "content-item__info__title"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-title1")) + " ")]), _c('div', {
    staticClass: "content-item__info__note"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-note1")) + " ")]), _c('div', {
    staticClass: "content-item__info__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text1")) + " "), _c('div', {
    staticClass: "hinde-sm enter-paragraphs"
  }), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text2")) + " ")])]), _c('img', {
    attrs: {
      "src": require("@/assets/toolImg/alpha3.png"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "content-item-full"
  }, [_c('Dot3', {
    staticClass: "dot-1"
  }), _c('Dot4', {
    staticClass: "dot-2"
  }), _c('div', {
    staticClass: "content-item-full__title"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-title2")) + " ")]), _c('div', {
    staticClass: "content-item-full__info"
  }, [_c('div', [_c('img', {
    staticClass: "hinde-md",
    attrs: {
      "src": require("@/assets/toolImg/alpha4.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content-item-full__info__note"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-note2")) + " ")]), _c('div', {
    staticClass: "content-item-full__info__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text3")) + " ")]), _c('img', {
    staticClass: "hinde-sm",
    attrs: {
      "src": require("@/assets/toolImg/alpha4.png"),
      "alt": ""
    }
  })]), _c('div', [_c('img', {
    staticClass: "hinde-md",
    attrs: {
      "src": require("@/assets/toolImg/alpha5.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content-item-full__info__note"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-note3")) + " ")]), _c('div', {
    staticClass: "content-item-full__info__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text6")) + " ")]), _c('img', {
    staticClass: "hinde-sm",
    attrs: {
      "src": require("@/assets/toolImg/alpha5.png"),
      "alt": ""
    }
  })])])], 1), _c('div', {
    staticClass: "content-item-full"
  }, [_c('div', {
    staticClass: "content-item-full__title"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-title3")) + " "), _c('div', {
    staticClass: "content-item-full__title__note"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text7")) + " "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text8")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text9")) + " ")])]), _c('div', {
    staticClass: "content-item-full__info"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/toolImg/alpha6.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content-item-full__info__note"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-note4")) + " ")]), _c('div', {
    staticClass: "content-item-full__info__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text10")) + " "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text11")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text12")) + " "), _c('div', {
    staticClass: "hinde-sm enter-paragraphs"
  }), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text13")) + " "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text14")) + " ")])])]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/toolImg/alpha7.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content-item-full__info__note"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-note5")) + " ")]), _c('div', {
    staticClass: "content-item-full__info__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text15")) + " "), _c('div', {
    staticClass: "hinde-sm enter-paragraphs"
  }), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text16")) + " "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text17")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text18")) + " ")])])]), _c('div', {
    staticClass: "content-item-full__info"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/toolImg/alpha8.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content-item-full__info__note"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-note6")) + " ")]), _c('div', {
    staticClass: "content-item-full__info__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text19")) + " "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text20")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text21")) + " "), _c('div', {
    staticClass: "hinde-sm enter-paragraphs"
  }), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text22")) + " ")])]), _c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/toolImg/alpha9.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content-item-full__info__note"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-note7")) + " ")]), _c('div', {
    staticClass: "content-item-full__info__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text23")) + " "), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text24")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text25")) + " "), _c('div', {
    staticClass: "hinde-sm enter-paragraphs"
  }), _c('span', {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text26")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text27")) + " ")])])])]), _c('div', {
    staticClass: "content-item"
  }, [_c('div', {
    staticClass: "content-item__info"
  }, [_c('div', {
    staticClass: "content-item__info__title-lg"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-title4")) + " ")]), _c('div', {
    staticClass: "content-item__info__text"
  }, [_c('span', {
    ref: "download",
    staticClass: "underline",
    on: {
      "click": function ($event) {
        return _vm.download();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text28")))]), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text29")) + " "), _c('div', {
    staticClass: "hinde-sm enter-paragraphs"
  }), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text30")) + " "), _c('span', {
    staticClass: "underline",
    on: {
      "click": function ($event) {
        return _vm.contactUs();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text31")))]), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text32")) + " ")]), _c('img', {
    staticClass: "img-sm",
    attrs: {
      "src": require("@/assets/toolImg/alpha10.png"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content-item__info__note"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-note8")) + " ")]), _c('div', {
    staticClass: "content-item__info__text"
  }, [_c('span', {
    staticClass: "underline bold-sm",
    on: {
      "click": function ($event) {
        return _vm.download();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text33")))]), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text34")) + " ")]), _c('div', {
    staticClass: "content-item__info__note"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-note9")) + " ")]), _c('div', {
    staticClass: "content-item__info__text"
  }, [_vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text35")) + " "), _c('span', {
    staticClass: "underline bold-sm",
    on: {
      "click": function ($event) {
        return _vm.contactUs();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("tools.alpha-generator.content-item-text36")))]), _vm._v(" " + _vm._s(_vm.$t("tools.alpha-generator.content-item-text37")) + " ")])]), _c('div', {
    staticClass: "img-group"
  }, [_c('Dot1', {
    staticClass: "dot-1"
  }), _c('Dot2', {
    staticClass: "dot-2"
  }), _c('Dot3', {
    staticClass: "dot-3"
  }), _c('Dot4', {
    staticClass: "dot-4"
  }), _c('Vector', {
    staticClass: "vector"
  }), _c('img', {
    attrs: {
      "src": require("@/assets/toolImg/alpha10.png"),
      "alt": ""
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }